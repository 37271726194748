import {Link}from "react-router-dom"

// import img_poli from 'assets/images/logos/imago_poli.svg';
import img_unam from 'assets/images/logos/imago_unam.svg';
import img_uba from 'assets/images/logos/imago_uni_buenos_aires.svg';
import img_uc from 'assets/images/logos/imago_uni_cordoba.svg';
import img_ucr from 'assets/images/logos/imago_uni_costa_rica.svg';
// import img_unp from 'assets/images/logos/imago_uni_nacional_plata.svg';
import img_uru from 'assets/images/logos/imago_uni_republica_uruguay.svg';
import img_ucol from 'assets/images/logos/imagotipo_uni_colombia.svg';

export default function Header() {

    return (
        <footer id="footer">
            <div className="logos univs">
                <img alt="" src={img_unam} />
                <img alt="" src={img_uba} />
                <img alt="" src={img_uc} />
                <img alt="" src={img_ucr} />
                <img alt="" src={img_uru} />
                <img alt="" src={img_ucol} />
            </div>
            <div className="enlaces">
                <Link href="acercade"> <span>Acerca de</span> </Link>
                <Link href="contacto"> <span>Contacto</span> </Link>
                <Link href="creditos"> <span>Créditos</span> </Link>
            </div>
            <div className="logo">  </div>
            <div className="leyenda">
                Hecho en México. Universidad Nacional Autónoma de México (UNAM). Todos los derechos
                reservados 2023. Esta página puede ser reproducida con fines no lucrativos, siempre
                y cuando se cite la fuente completa y su dirección electrónica, y no se mutile; de
                otra forma requiere permiso previo por escrito de la institución.
            </div>

        </footer>

    )
}


